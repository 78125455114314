<template>
  <ComplianceGrid />
</template>

<script>
import ComplianceGrid from "@/components/ComplianceGrid";

export default {
  name: "ComplianceExternal",
  components: {
    ComplianceGrid: ComplianceGrid,
  }
};
</script>
